<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{$vuetify.lang.t('$vuetify.department.list')}}
      </v-card-title>
      <v-card-text>
        <v-row
          class="pa-4"
          justify="space-between"
        >
          <v-col cols="6">
            <v-treeview
              :items="departmentItems"
              activatable
              hoverable
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.children || item.children.length == 0">
                  mdi-account
                </v-icon>
              </template>
              <template v-slot:append="{item}">
                <v-btn
                  v-if="item.id !== -1"
                  class="mx-1"
                  color="error"
                  fab
                  dark
                  x-small
                  @click.stop="handleDel(item)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.id !== -1"
                  class="mx-1"
                  color="warning"
                  fab
                  dark
                  x-small
                  @click.stop="handleAdminUserDialog(item)"
                >
                  <v-icon dark>
                    mdi-account-tie
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.id !== -1"
                  class="mx-1"
                  color="cyan"
                  fab
                  dark
                  x-small
                  @click.stop="handleEdit(item)"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  class="mx-1"
                  color="primary"
                  fab
                  dark
                  x-small
                  @click.stop="handleAdd(item)"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col
            class="d-flex text-center"
          >
            <v-scroll-y-transition mode="out-in">
              <div>
                <div
                  v-if="!selected"
                  class="title grey--text text--lighten-1 font-weight-light"
                  style="align-self: center;"
                >
                  {{$vuetify.lang.t('$vuetify.department.selectADepartment')}}
                </div>
                <base-form
                  ref="baseForm"
                  :display="selected"
                  :edit-status="editStatus"
                  :parent-dep="editedItem"
                  @submit="handleFormSubmit"
                />
              </div>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          警告
        </v-card-title>
        <v-card-text v-if="editedItem">
          确定要删除
          <Strong>
            {{ editedItem.showName }}
          </Strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            删除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="adminUserDialog"
      persistent
      max-width="800"
    >
      <change-admin-user
        v-if="adminUserDialog"
        :dep-info="editedItem"
        @cancel="handleShowAdminUserCancel"
        @submit="handleShowAdminUserSubmit"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { listToTree } from '@/utils';

  export default {
    components: {
      BaseForm: () => import('./BaseForm'),
      ChangeAdminUser: () => import('./ChangeAdminUser')
    },
    data: () => ({
      selected: false,
      deleteDialog: false,
      adminUserDialog: false,
      editStatus: null,
      editedItem: null,
      departmentItems: []
    }),

    computed: {
      ...mapGetters({
        departments: 'department/departments'
      })
    },

    created () {
      this.initialize();
    },

    methods: {
      ...mapActions({
        departmentQueryAll: 'department/queryAll',
        departmentUpdate: 'department/update',
        departmentCreate: 'department/create',
        departmentDelete: 'department/delete'
      }),
      initialize () {
        this.departmentQueryAll().then( () => {
          this.loadDepartmentItems()
        });
      },
      loadDepartmentItems() {
        let deps = JSON.parse(JSON.stringify(this.departments))
        this.departmentItems = listToTree(deps, { name: 'showName' })
      },
      handleDel (item) {
        this.editedItem = Object.assign({}, item)
        this.deleteDialog = true
      },

      handleEdit (item) {
        this.selected = true
        this.editStatus = 'E'
        this.editedItem = Object.assign({}, item)
        this.$nextTick( () => {
          this.$refs.baseForm.initialize()
        })
      },

      handleAdd (item) {
        this.selected = true
        this.editStatus = 'N'
        this.editedItem = Object.assign({}, item)
        this.$nextTick( () => {
          this.$refs.baseForm.initialize()
        })
      },

      handleFormSubmit (data) {
        console.log(this.editStatus)
        if (this.editStatus === 'E') {
          this.departmentUpdate(data).then( () => {
            this.loadDepartmentItems()
            this.editStatus = null
          })
        } else {
          this.departmentCreate(data).then( () => {
            this.loadDepartmentItems()
            this.editStatus = null
          })
        }
      },

      handleDeleteDialog() {
        this.deleteDialog = false
        this.departmentDelete(this.editedItem).then( () => {
          this.loadDepartmentItems()
        })
      },

      handleAdminUserDialog (data) {
        this.adminUserDialog = true
        this.editedItem = data
      },

      handleShowAdminUserCancel () {
        this.adminUserDialog = false
      },

      handleShowAdminUserSubmit () {
        this.adminUserDialog = false
        this.loadDepartmentItems()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      }
    },
  }
</script>
I
